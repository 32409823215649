// import { Container, makeStyles } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "./timer.css";
// import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";

let intervalId = null;

function CountDownTimer(props) {
  
  const [timer, setTimer] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    
    if(intervalId != null)
    {
      clearInterval(intervalId);
    }   

    intervalId = setInterval(function (){
        
        //var nowDate = new Date();
        //var utc_timestamp = Date.UTC(nowDate.getUTCFullYear(),nowDate.getUTCMonth(), nowDate.getUTCDate() , 
        //nowDate.getUTCHours(), nowDate.getUTCMinutes(), nowDate.getUTCSeconds(),nowDate.getUTCMilliseconds());        
        //console.log(props.Time);
        //console.log(utc_timestamp);
        
        //var now = moment(utc_timestamp);
        var now = moment(props.Now);
        //var now = moment(Date.UTC());
        const end = props.Time;
        const days = moment(end).diff(now, "days");
        const hours = moment(end).diff(now, "hours") - days * 24;
        const minutes = moment(end).diff(now, "minutes") - days * 1440 - hours * 60;
        const seconds =
          moment(end).diff(now, "seconds") -
          (days * 86400 + hours * 3600 + minutes * 60);

        setTimer({
          days: days > 0 ? days : 0,
          hours: hours > 0 ? hours : 0,
          minutes: minutes > 0 ? minutes : 0,
          seconds: seconds > 0 ? seconds : 0
        });
        
      }, 1000);
  }, [props.Time]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box className="li span">
        <div className="number">
          {timer.days} 
        </div>
        <div className="ltr">Day</div>
        <Box
          sx={{
            display: { md: "none" },
            zoom: 0.5,
          }}
        >
          {/* <CalendarTodayIcon /> */}
        </Box>
      </Box>
      <Box className="li span">
        <div className="number">
          {timer.hours}
        </div>
        <div className="ltr"> Hour</div>
      </Box>
      <Box className="li span">
        <div className="number">
          {timer.minutes} 
        </div>
        <div className="ltr">Minute</div>
      </Box>
      <Box className="li span">
        <div className="number">
          {timer.seconds} 
        </div>
        <div className="ltr">Second</div>
      </Box>
    </Box>
  );
}

export default CountDownTimer;
